<template>
  <div class="page-welcome page-content">
    <div class="top">
      <div class="image-wrapper">
        <img class="image" src="" v-image-from-storage="image" alt="Hero Image">
        <KlubbaLoaderIcon />
      </div>
      <div class="description">
        <div class="title" v-html="club.welcome_title" />
        <div class="text" v-html="club.welcome_message" />
      </div>
    </div>
    <klubba-button
      @click="handleJoin"
      buttonText="Join"
      customClass="bg-primary mt-auto"
    />
  </div>
</template>

<script>
import KlubbaButton from '@/components/default/KlubbaButton.vue';
import KlubbaLoaderIcon from '../../components/default/KlubbaLoaderIcon.vue';

export default {
  name: 'Welcome',
  components: {
    KlubbaLoaderIcon,
    KlubbaButton,
  },
  computed: {
    club() {
      return this.$store.getters.club;
    },
    image() {
      return this.club?.splash_image ?? '';
    },
  },
  methods: {
    handleJoin() {
      this.$router.push(`/${this.club.id}/product-details`);
    },
  },
};
</script>

<style lang="sass" scoped>
.page-welcome
  @apply p-8 bg-white w-full
  min-height: calc(var(--vh, 1vh) * 100)

  .image
    @apply object-cover absolute left-0 top-0 right-0 bottom-0 m-auto
    transform: translate3d(0, 0, 0)
    min-width: 100%
    min-height: 100%

  .image-wrapper
    @apply w-full h-0 overflow-hidden rounded-radius-10 relative block bg-background
    padding-bottom: 56%
    transform: translate3d(0, 0, 0)

  .description
    @apply px-2 my-0

    .title
      @apply text-black-light text-xl mt-8 leading-6 font-medium

    .text
      @apply my-6 text-black-dark leading-5
      :deep(p:not(:first-child))
        @apply mt-5
</style>
